import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

const SEO = ({ metadata }) => {
	const { site } = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
	`)

	const defaultTitle = site.siteMetadata?.title
	const metaDescription = metadata.meta_desc || site.siteMetadata.description
	const metaAuthor = site.siteMetadata?.author

	return (
		<Helmet
			title={metadata.meta_title}
			titleTemplate={defaultTitle ? `${defaultTitle} - %s` : null}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: metadata.meta_title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: metadata.meta_image?.imageFile.publicURL,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					name: `twitter:creator`,
					content: metaAuthor,
				},
				{
					name: `twitter:title`,
					content: metadata.meta_title,
				},
				{
					property: `twitter:description`,
					content: metaDescription,
				},
				{
					property: `twitter:image`,
					content: metadata.meta_image?.imageFile.publicURL,
				},
				{
					name: 'p:domain_verify',
					content: '2ec013a0f132dae639213722eb2fa0fd',
				},
			]}
		/>
	)
}

SEO.propTypes = {
	metadata: PropTypes.shape({
		meta_title: PropTypes.string.isRequired,
		meta_desc: PropTypes.string.isRequired,
		meta_image: PropTypes.shape({
			imageFile: PropTypes.shape({
				publicURL: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
}

export default SEO
