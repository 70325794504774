import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import NavLogo from './navigation/logo'

const Footer = ({ page }) => {
	return (
		<footer className="relative overflow-hidden pt-40 lg:pt-60 xl:pt-80">
			{/* Footer Content */}
			<div className="container pb-120 sm:pb-120 md:pb-140 lg:pb-180 xl:pb-200">
				<div className="w-10/12 mx-auto text-center">
					<h2 className="h1">{page.footer_headline}</h2>
					<Link className="hover:text-blue" to={page.footer_link_url}>
						<h2 className="h1 pt-10 underline">{page.footer_link_text}</h2>
					</Link>
				</div>
			</div>

			{/* Page Logo at the Bottom */}
			<div className="absolute bottom-0 w-full transform-gpu translate-y-1/2">
				<div className="flex flex-row justify-center content-center flex-wrap absolute left-2/4 bottom-footerLinks -translate-x-1/2 transform-gpu z-10 font-medium text-16 md:text-18 lg:text-24 text-center w-full">
					<a
						href="https://instagram.com/opposite.hq"
						rel="noreferrer"
						target="_blank"
						className="hover:text-blue mr-10 md:mr-50"
					>
						Instagram
					</a>
					<h5 className="font-medium text-16 md:text-18 lg:text-24 order-first md:order-none min-w-full md:min-w-0">
						@ 2012 - {new Date().getFullYear()} Opposite LLP
					</h5>
					<Link className="hover:text-blue ml-10 md:ml-50" to="/privacy-policy">
						Privacy Policy
					</Link>
				</div>

				<NavLogo />
			</div>
		</footer>
	)
}

Footer.propTypes = {
	page: PropTypes.shape({
		footer_headline: PropTypes.string.isRequired,
		footer_link_url: PropTypes.string.isRequired,
		footer_link_text: PropTypes.string.isRequired,
	}).isRequired,
}

export default Footer
