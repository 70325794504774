import * as React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

// Mobile Navigation Toggle Button

const NavToggle = ({ toggle }) => {
	return (
		<motion.button
			onClick={toggle}
			className="fixed lg:hidden top-15 right-15 p-15 z-30 rounded-full focus:outline-none"
			variants={{
				closed: { boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' },
				open: { boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0)' },
			}}
		>
			<svg width="20" height="20" viewBox="0 0 20 20">
				{/* Hamurger Menu to Close Icon Animation */}
				<Path
					variants={{
						closed: { d: 'M 0 2 L 20 2' },
						open: { d: 'M 2 18 L 18 2' },
					}}
				/>
				<Path
					d="M 0 10 L 20 10"
					variants={{
						closed: { opacity: 1 },
						open: { opacity: 0 },
					}}
					transition={{ duration: 0.1 }}
				/>
				<Path
					variants={{
						closed: { d: 'M 0 18 L 20 18' },
						open: { d: 'M 2 2 L 18 18' },
					}}
				/>
			</svg>
		</motion.button>
	)
}

NavToggle.propTypes = {
	toggle: PropTypes.func.isRequired,
}

// Hamburger Menu Icon

const Path = props => (
	<motion.path fill="transparent" strokeWidth="2" stroke="#333333" {...props} />
)

Path.propTypes = {
	d: PropTypes.string,
	variants: PropTypes.shape({}).isRequired,
}

export default NavToggle
