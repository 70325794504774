import * as React from 'react'
import PropTypes from 'prop-types'

import Seo from './seo'
import Header from './header'
import Footer from './footer'

const Layout = ({ children, page, metadata }) => {
	return (
		<>
			<Seo metadata={metadata} />
			<Header />
			<main className="overflow-hidden">{children}</main>
			<Footer page={page} />
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	page: PropTypes.shape({}).isRequired,
	metadata: PropTypes.shape({}).isRequired,
}

export default Layout
