import * as React from 'react'
import { useLayoutEffect } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

// Mobile Menu with List of Page Links

const NavMenu = ({ toggle, pages }) => {
	useLockBodyScroll()

	return (
		<motion.nav
			className="fixed max-w-xs sm:max-w-sm md:max-w-md h-full px-40 pt-100 pb-80 flex flex-col justify-between z-30"
			variants={links}
		>
			{pages.map(page => {
				return (
					<Link
						key={`nav_menu_${page.id}`}
						className="hover:text-blue"
						activeClassName="underline underline-offset-10"
						onClick={toggle}
						to={page.url}
					>
						<h3 className="text-flexible">{page.title}</h3>
					</Link>
				)
			})}
		</motion.nav>
	)
}

NavMenu.propTypes = {
	toggle: PropTypes.func.isRequired,
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
}

// Framer Clip Link Slide Animation Variants

const links = {
	open: {
		opacity: 1,
		x: 0,
		transition: {
			x: { stiffness: 1000, velocity: -100 },
		},
	},
	closed: {
		opacity: 0,
		x: -1000,
		transition: {
			x: { stiffness: 1000 },
		},
	},
}

// Prevent Scroll when Nav Menu is Open

function useLockBodyScroll() {
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow // Get original body overflow
		document.body.style.overflow = 'hidden' // Prevent scrolling on mount
		return () => (document.body.style.overflow = originalStyle) // Re-enable scrolling when component unmounts
	}, []) // Empty array ensures effect is only run on mount and unmount
}

export default NavMenu
