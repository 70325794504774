import * as React from 'react'

import OppositeLogo from '../../assets/opposite.inline.svg'

// Common Logo Component to be used in Header/Footer

const NavLogo = () => (
	<OppositeLogo className="relative mx-auto w-full lg:px-40 xl:px-60 xxl:px-80 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl xxl:max-w-xxl h-logo" />
)

export default NavLogo
