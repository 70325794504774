import * as React from 'react'
import { motion, useCycle } from 'framer-motion'
import { Link } from 'gatsby'

import NavMenu from './navigation/menu'
import NavToggle from './navigation/toggle'
import NavLogo from './navigation/logo'

var pages = [
	{ id: 0, title: 'Home', url: '/' },
	{ id: 1, title: 'Our Work', url: '/portfolio' },
	{ id: 2, title: 'About', url: '/about' },
	{ id: 3, title: 'Careers', url: '/careers' },
	{ id: 4, title: 'Blog', url: '/blog' },
	{ id: 5, title: 'Contact', url: '/contact' },
]

const Header = () => {
	const [isOpen, toggleOpen] = useCycle(false, true)

	return (
		<header className="lg:pb-40 xl:pb-60">
			{/* Page Logo at the Top */}
			<div className="absolute top-0 w-full transform-gpu -translate-y-1/2">
				<NavLogo />
			</div>

			{/* Mobile Navigation */}
			<motion.div
				initial={false}
				animate={isOpen ? 'open' : 'closed'}
				className="lg:hidden"
			>
				{isOpen && <NavMenu toggle={() => toggleOpen()} pages={pages} />}

				<NavToggle toggle={() => toggleOpen()} />

				{/* Clip Animation Background & In-Menu Logo */}
				<motion.div
					className="fixed lg:hidden top-0 left-0 w-screen h-screen bg-white z-20"
					variants={background}
				>
					<motion.div
						className="top-0 fixed w-full transform-gpu -translate-y-1/2"
						variants={logo}
					>
						<NavLogo />
					</motion.div>
				</motion.div>
			</motion.div>

			{/* Desktop Navigation */}
			<div className="container">
				{!isOpen && (
					<nav className="relative hidden lg:flex flex-row justify-between lg:py-30 xl:py-40 z-30">
						{pages.map(page => {
							return (
								<Link
									key={`nav_links_${page.id}`}
									className="hover:text-blue"
									activeClassName="underline underline-offset-10"
									partiallyActive={page.id === 0 ? false : true}
									to={page.url}
								>
									<h3>{page.title}</h3>
								</Link>
							)
						})}
					</nav>
				)}
			</div>
		</header>
	)
}

// Framer Clip Animation Background Variants
const background = {
	open: {
		clipPath: `circle(1400px at calc(100% - 40px) 40px)`,
		transition: {
			type: 'spring',
			stiffness: 200,
			damping: 20,
			restDelta: 2,
		},
	},
	closed: {
		clipPath: 'circle(25px at calc(100% - 40px) 40px)',
		transition: {
			type: 'spring',
			stiffness: 500,
			damping: 40,
		},
	},
}

// Framer Clip Animation Logo Variants
const logo = {
	open: {
		opacity: 1,
		transition: { duration: 0.25 },
	},
	closed: {
		opacity: 0,
		transition: { duration: 0.25 },
	},
}

export default Header
